import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useMemo, useState } from 'react';
import { useToasts } from '~/context/ToastContext';
import { useUser } from '~/context/UserContext';
import ErrorIcon from '~/icons/ErrorIcon';
import formStyles from '~/scss/layout/form.module.scss';

import { useFetcher } from '@remix-run/react';

import Spinner from '../Spinner/Spinner';
import UnsubscribeModal from '../UnsubscribeForm/UnsubscribeModal';
import styles from './NewsletterForm.module.scss';

const renderCheckmarkIcon = () => (
  <svg
    className={styles.checkMark}
    width='12'
    height='10'
    viewBox='0 0 12 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 1.5L4.3333 8.5L1 5'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

interface ActionData {
  status: number;
  errors?: { selected_groups: string };
}

interface CheckboxOptionProps {
  value: string;
  title: string;
  description: string;
  isChecked: boolean;
  inModal?: boolean;
}

const CheckboxOption: FC<CheckboxOptionProps> = ({
  value,
  title,
  description,
  isChecked,
}) => (
  <label aria-label={title}>
    <input
      value={value}
      name='selected_groups[]'
      className={styles.checkbox}
      type='checkbox'
      defaultChecked={isChecked}
    />
    <div className={styles.box}>
      <div className={styles.fakeCheckbox}>{renderCheckmarkIcon()}</div>
      <div>
        <div className={styles.checkboxTitle}>{title}</div>
        {description}
      </div>
    </div>
  </label>
);

interface Props {
  onClose?: () => void;
  inModal?: boolean;
}

const NewsletterForm: FC<Props> = ({ onClose, inModal = false }) => {
  const { user } = useUser();
  const fetcher = useFetcher<ActionData>();
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const { createToast } = useToasts();

  const isSubmitting = fetcher.state === 'submitting';
  const error = fetcher.data?.errors?.selected_groups;

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.state === 'idle') {
      createToast(
        'success',
        "Done! You've successfully subscribed to our Newsletter",
      );
      onClose?.();
    }
  }, [fetcher.data, fetcher.state, createToast, onClose]);

  const userGroups = useMemo(() => user?.mailer_lite_groups || [], [user]);

  return (
    <>
      <fetcher.Form method='post' action='/user-profile/newsletter'>
        <div className={styles.formLayout}>
          {[
            {
              value: 'daily',
              title: 'Daily',
              description:
                'Start your day with a burst of updates. Receive daily news in your inbox.',
            },
            {
              value: 'weekly',
              title: 'Weekly',
              description:
                "Catch up with a handpicked overview of the week's must-know news and stories.",
            },
            {
              value: 'personalized',
              title: 'Personalized',
              description:
                'Stay updated on your followed topics and tags. Enjoy a personalized newsletter!',
            },
          ].map((option) => (
            <CheckboxOption
              key={option.value}
              value={option.value}
              title={option.title}
              description={option.description}
              isChecked={
                !!userGroups.find((group) => group.name === option.value) ||
                inModal
              }
            />
          ))}
        </div>
        <div
          className={classNames(styles.buttons, {
            [styles.buttonsInModal]: inModal,
          })}
        >
          {inModal && (
            <button
              type='button'
              className={classNames(
                formStyles.submitButton,
                formStyles.submitButtonDark,
              )}
              onClick={onClose}
            >
              Maybe later
            </button>
          )}
          {!inModal && !!user?.mailer_lite_groups.length && (
            <button
              onClick={() => setShowUnsubscribeModal(true)}
              className={classNames(
                formStyles.submitButton,
                formStyles.submitButtonDark,
              )}
              disabled={isSubmitting}
              type='button'
            >
              Unsubscribe
            </button>
          )}
          <button
            className={classNames(formStyles.submitButton, styles.submitButton)}
            type='submit'
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner />
            ) : user?.mailer_lite_groups.length ? (
              'Change'
            ) : (
              'Subscribe'
            )}
          </button>
        </div>
        {error && (
          <div className={formStyles.errorMessage}>
            <ErrorIcon />
            {error}
          </div>
        )}
      </fetcher.Form>
      <AnimatePresence>
        {showUnsubscribeModal && (
          <UnsubscribeModal onClose={() => setShowUnsubscribeModal(false)} />
        )}
      </AnimatePresence>
    </>
  );
};

export default NewsletterForm;
