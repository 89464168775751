import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { useToasts } from '~/context/ToastContext';
import ErrorIcon from '~/icons/ErrorIcon';
import formStyles from '~/scss/layout/form.module.scss';

import { useFetcher } from '@remix-run/react';

import Spinner from '../Spinner/Spinner';
import styles from './UnsubscribeModal.module.scss';

interface ActionData {
  status: number;
  errors?: string[];
}

interface Props {
  onClose: () => void;
}

const UnsubscribeModal: FC<Props> = ({ onClose }) => {
  const fetcher = useFetcher<ActionData>();
  const isSubmitting = fetcher.state === 'submitting';
  const error = fetcher.data?.errors;
  const { createToast } = useToasts();

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.state === 'idle') {
      createToast(
        'success',
        "You've been unsubscribed. Hope to see you back soon!",
      );
      onClose();
    }
  }, [fetcher.data, fetcher.state, createToast, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <h3 className={styles.heading}>
          Are you sure you want to unsubscribe newsletter?
        </h3>
        <fetcher.Form method='post' action='/unsubscribe'>
          <div className={styles.buttons}>
            <button
              onClick={() => onClose()}
              className={classNames(
                formStyles.submitButton,
                formStyles.submitButtonDark,
              )}
              type='button'
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              className={formStyles.submitButton}
              type='submit'
              disabled={isSubmitting}
            >
              {isSubmitting ? <Spinner /> : 'Unsubscribe'}
            </button>
          </div>
          {!!error && (
            <div className={formStyles.errorMessage}>
              <ErrorIcon />
              There was an error, try again later
            </div>
          )}
        </fetcher.Form>
      </div>
    </motion.div>
  );
};

export default UnsubscribeModal;
